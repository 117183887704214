import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Icon component
 */
@Component({
  selector: 'icon',
  template: `
    <span class="_container {{classList}}"
      [inlineSVG]="iconId + '.svg'">
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`:host{display:inline-block}:host /deep/ svg{fill:inherit;height:100%;width:100%}._container{display:inline-flex;vertical-align:middle}`]
})
export class IconComponent {
  @Input() public iconId: string;
  @Input() public classList: string;
}
