/**
 * RyButtonStyle map to css classes defined into button.component.scss
 */
export enum RyButtonStyle {
  ANCHOR_BLUE = 'anchor-blue',
  FLAT_BLUE = 'flat-blue',
  FLAT_DARK_BLUE = 'flat-dark-blue',
  FLAT_YELLOW = 'flat-yellow',
  GRADIENT_BLUE = 'gradient-blue',
  GRADIENT_YELLOW = 'gradient-yellow'
}
