import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RyAnchorComponent } from './anchor/anchor.component';
import { RyButtonComponent } from './button/button.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RyButtonComponent, RyAnchorComponent],
  exports: [RyButtonComponent, RyAnchorComponent]
})
export class RyButtonModule {
}
